@font-face {
    font-family: ourfitFont;
    src: url(./fonts/ourfit/Outfit-VariableFont_wght.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');


body {
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: ourfitFont;
}

body {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

a {
    text-decoration: none;
    text-transform: none;
}

main{
    background-image: url(./images/stars.jpg);
    background-color: #000113;
}

/* Canvas */

#menu-btn {
    position: absolute;
    z-index: 998;
}

.menu-container {
    display: none;
}

:root {
    --dark-bg: rgba(15, 15, 15, 0.95);
    --spacing: 350px;

    font-family: sans-serif;
    font-weight: 400;
    font-style: normal;
}

/*main {*/
/*  width: 100%;*/
/*  color: white;*/
/*  z-index: 99;*/
/*  margin: 0px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  padding: 0px;*/
/*  position: relative;*/
/*}*/


.scroll {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    left: 0;
}

/*/////////////////////////////  Botones fijos ///////////////////////////// */

#logo {
    position: fixed;
    top: .5rem;
    left: 1.5rem;
    z-index: 997;
}

#logo img {
    height: 80px;
    width: 180px;
    margin-top: .5rem;
}

#projects-btn {
    position: fixed;
    font-weight: 200;
    top: 0;
    right: 1px;
    z-index: 998;
}

#open-contact-us {
    position: fixed;
    font-weight: 200;
    bottom: 0;
    right: 1px;
    z-index: 998;
}

#open-menu-mobile {
    position: fixed;
    top: 0;
    right: 1px;
    z-index: 998;
    display: none;
    height: 35px;
    margin-top: 0.5rem;
}

#open-menu-mobile svg.iconContact {
    height: 30px;
}

#mision-btn-id {
    position: fixed;
    font-weight: 200;
    bottom: 0;
    left: 1px;
    z-index: 998;
}

.fixed-icon-btn {
    border: none;
    background: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1.5rem 3rem 1.5rem 2rem;
    text-decoration: none;
    text-transform: none;
}

.fixed-icon-btn img {
    height: 40px;
    width: 40px;
}

.fixed-icon-btn h2 {
    font-size: 1rem;
    letter-spacing: 2px;
    margin: 5px 0 0 0;
    font-family: 'Inter', sans-serif;
}

#previous-btn {
    position: fixed;
    font-weight: 200;
    top: 50%;
    left: 2rem;

    color: white;
    z-index: 999;
}

#next-btn {
    position: fixed;
    font-weight: 200;
    top: 50%;
    right: 2rem;

    color: white;
    z-index: 999;
}

/*//////////////////////  Fin Botones Fijos //////////////////////// */

/*/////////////////////////////  Intro ///////////////////////////// */

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.logo-container .logo {
    height: 55px;
    width: 400px;
}

.container-aurora-img-vertical {
    margin: 2.5em auto 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.main-project-img-vertical, .main-project-img-horizontal{
    width: 60%;
    display: none;
    animation: floating 6s infinite;
}

.arrow-container {
    flex: 1;
    transform: scale(.85);
}

.arrow-container .arrow-down {
    height: 40px;
    width: 40px;
}

.header p {
    flex: 2;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    line-height: 3rem;
    max-width: 540px;
}

.hand-scroll{
    display: none;
}

/*/////////////////////////////  Fin de intro ///////////////////////////// */

.elem {
    padding: 20px;
    width: 60%;
    max-width: 700px;
}

.elem h2 {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin: 0;
    color: #FEB154;
    font-size: 2rem;
    letter-spacing: 0.2rem;
}

.elem h3 {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-top: 2rem;
    font-size: 1.7rem;
    color: #FEB154;
    letter-spacing: 0.2rem;
}

.elem p {
    font-weight: 300;
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 1.5;
}

.elem p span{
    font-weight: 300;
    text-align: center;
    color: #FEB154;
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 1.5;
}

.page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.intro-remark {
    color: #FEB154;
}

/*/////////////////////////////  Contactanos  ///////////////////////////// */
.contact-us-container {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    flex: 0 0 auto;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url(./images/stars.jpg);
    background-color: #000113;
}

.contact-us-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 6
}

.contact-us-info-mobile {
    display: flex;
    flex: 5;
    flex-direction: column;
    justify-content: center;
}

.contact-us-container h1 {
    font-size: 2.8rem;
    font-weight: 500;
    color: white;
}

.contact-us-container p {
    width: 50%;
    font-size: 1.8rem;
    font-weight: 300;
    color: white;
}

.contact-us-container .mail-link {
    border: none;
    background: none;
    color: #FEB154;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.contact-us-container .ig-link {
    border: none;
    background: none;
    color: white;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    margin-top: 1rem;
}

.contact-us-container button {
    font-size: 1.5rem;
}

.contact-us-info .media-icon-container{
    display: flex;
    justify-content: space-evenly;
    margin: 6rem auto 0rem;
    width: 200px;
}

.media-icon{
    height: 40px;
}

/* .contact-us-container button:hover{
  color: #ff8b00;
} */

.go-back-btn {
    background: none;
    border: none;
    font-size: 1.4rem;
    color: gray;
    flex-grow: 1;
    justify-self: flex-end;
    flex: 1
}

.go-back-btn:hover {
    color: rgb(187, 177, 177);
}

/*/////////////////// Fin de contactanos ///////////////////////////// */

/*///////////////////////////// Cargando ///////////////////////////// */

.loading-container {
    height: 100vh;
    z-index: 1000;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    /* display: flex; */
    display: none;
    justify-content: center;
    align-items: center;
}

/*/////////////////////////////  Fin de cargando ///////////////////////////// */

/*////////////////////////////  Menú de proyectos //////////////////////////// */

.orbit-circle-menu{
    height: 100vh;
    position: absolute;
}

.project-option-container {
    display: flex;
    align-items: center;
    z-index: 3;
    width: auto;
    margin-left: 20rem;
    margin-bottom: 1.5rem;
}


.project-option-container:nth-child(3) {
    margin-left: 22rem;
}

.project-option-container:nth-child(4) {
    margin-left: 22rem;
}

.project-image {
    height: 120px;
}

.project-name {
    color: #a4a4a4;
    font-size: 1.3rem;
    margin-left: 25px;
    max-width: 180px;
}

.project-option-container:hover .project-image {
    height: 130px;
}

.project-option-container:hover .project-image-container {
    padding: 15px;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    display: flex;
}

.project-image-container{
    transition: border .5s;
}

.project-option-container:hover .project-name {
    font-size: 1.8rem;
    font-weight: 600;
    color: #ffffff;
}


.project-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.projects-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    /* margin-left: 15rem; */
}

.project-menu h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: grey;
    text-align: start;
    margin-bottom: 4rem;
}

.project-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* flex: 1; */
    margin: 0;
    padding: 0;
}

.project_title {
    list-style-type: none;
    font-size: 2rem;
    font-weight: 800;
    color: white;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
}

/*  */

.project_title {
    background-image: linear-gradient(
            to right,
            #FEB154,
            #FEB154 50%,
            rgba(0, 0, 0, 0) 50%
    );
    background-size: 200% 100%;
    background-position: 0;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
}

.project_title:before {
    content: '';
    background: #FEB154;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.5s ease-in-out;
}

.project_title:hover {
    -webkit-text-fill-color: transparent;
    /* background-position: -100%; */
}

.project_title:hover::before {
    width: 100%;
}

/*  */

/*/////////////////////////////  fin menú de proyectos ///////////////////////////// */


/*/////////////////////////////  Mora ///////////////////////////// */

.canvas-project {
    z-index: 1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80vw;
    outline: none;
    position: relative;
}

.intro-project-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40%;
    margin-left: 18rem;
}

.intro-project-container p {
    font-size: 2.2rem;
    font-weight: 600;
}

.mora-project-container {
    padding: 5rem 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #000113;
    /* ver porqué apsa lo del margin */
    margin: -10px;
}

.project-logo {
    max-width: 260px;
    max-height: 100px;
}

.colaboration-and-summary-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 6rem;
}

.text-summary {
    flex: 2;
    font-size: 2.2rem;
}

.colaborators-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.collaborator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1.2rem;
    margin: 0;
    font-weight: 100;
}

.collaboratorsInfoContainer .collaboratorLink {
    margin: 0 0 .5rem 0;
}

.collaborator p {
    margin: 15px 0 0 0;
}

.collaborator a {
    font-weight: 800;
    text-decoration: none;
}

.collaborator span {
    font-weight: 900;
}

.colaborators-container .grey {
    color: grey;
}

.images-gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6rem;
}

.image-cel-mora {
    height: 540px;
    width: 323px;
}

.text-more-info {
    font-size: 1.5rem;
    font-weight: 100;
    width: 100%;
}

iframe {
    margin: 5rem 0;
}

.links-container {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom:  3rem;
}

.link-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    text-align: center;
}

.link-container a {
    font-size: 1.5rem;
    color: #a169d7;
    font-weight: 100;
}

.link-container a:hover {
    color: #FEB154;
}

.link-container span {
    font-size: 1.5rem;
    font-weight: 100;
    color: #ffffff;
}

.nextProjectLink{
    font-size: 1.5rem;
    font-weight: 100;
    color: #9e9e9e;
}

/*/////////////////////////////  Fin Mora ///////////////////////////// */


/*/////////////////////////////  Aurora ///////////////////////////// */

/* * {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  padding: 8px 16px;
  margin: 10px 5px;
}

main {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
} */

.base-scroll {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 80vh;
    margin: 5em 0;
}

.slides-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.slide {
    position: absolute;
    font-size: 90px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    width: calc(100% / 3);
    width: 100%;
}

.slides-inner {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.projectContent .slide iframe{
    width: 1200px;
    z-index: 1;
}

.arrowBtnContainer{
    position: absolute;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
}

.circleProgress {
    transform: translateY(2em);
    z-index: 4;
}

.introContainer {
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: 30rem;
    margin-left: 20rem;
    font-weight: 400;
    font-size: 1.7rem;
    color: white;
}

.projectContent {
    max-width: 1200px;
    margin: 0 5em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectContainer {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: revert;
    align-items: center;
    color: white;

    width: 100vw;
}

.summaryParagraphContainer {
    border-left: solid;
    border-left-width: 1px;
    border-left-color: white;
    margin-bottom: 10rem;
}

.summaryParagraph {
    margin: 0 0 0 50px;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 2.5rem;
}

.collaboratorsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5em;
    align-items: center;
    max-height: 540px;
}

.img-container {
    width: 60%;
    display: flex;
    justify-content: space-between;
    padding-right: 6%;
}

.imageVerticalProject {
    width: 44%;
}

.collaboratorsInfoContainer {
    border: solid;
    border-radius: 11px;
    padding: 15px 20px 40px;
    display: flex;
    flex-direction: column;
    width: 36%;
    font-weight: 100;
}

.collaborationBoxBaseText {
    font-size: 1.3rem;
    margin: 0.8rem 0;
}

.imageHorizontalProject {
    width: 70%;
    margin-right: 3%;
}

.collaboratorParagraph {
    margin: 11px 0 0 0;
}

.collaboratorLink {
    font-weight: 500;
    text-decoration: none;
    color: #a169d7;
}

.collaboratorSpan {
    font-weight: 500;
    margin-left: 5px;
}

.textMoreInfo {
    font-size: 1.5rem;
    font-weight: 100;
    width: 100%;
    line-height: 2.5rem;
}


.projectContent .faduIframe{
    margin-bottom: 0;
}