/*/ Media queries.css /*/

/*////////////////// min-width: 1024px ///////////////////*/

@media (min-width: 1024px) {

}

/*////////////////////////////////////////////////////////
////////////////// max-width: 1650px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 1650px) {

    .projectContent {
        max-width: 1100px;
        width: 70%;
    }

    .imageVerticalProject {
        width: 48%;
    }

    .projectContent .slide iframe{
        width: 70%;
    }

    .arrowBtnContainer{
        max-width: 1300px;
    }

}

/*////////////////////////////////////////////////////////
////////////////// max-width: 1400px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 1400px) {

    .projectContent {
        max-width: 65%;
        margin: 0 180px;
    }

    .summaryParagraph, .text-more-info {
        font-size: 1.3em;
        line-height: 2.2rem;
    }

    .collaboratorsContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 6em;
        align-items: center;
        flex-direction: column;
        max-height: inherit;
    }

    .img-container {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding-right: 0;
    }

    .imageVerticalProject {
        width: 40%;
    }

    .imageHorizontalProject {
        width: 100%;
        margin-right: 0;
    }

    .projectContent iframe {
        height: 500px;
    }

    .projectContent .slide iframe {
        height: 550px;
        width: 860px;
    }

    .arrowBtnContainer {
        max-width: 1000px;
    }

    .circleProgress {
        transform: translateY(1em);
    }

    .collaboratorsInfoContainer {
        width: 80%;
        margin-top: 8rem;
        box-sizing: border-box;
        padding: 15px 50px 30px;
    }

    .collaborationBoxBaseText {
        text-align: center;
    }

    .collaborator {
        text-align: center;
        margin: 0 0 1rem 0;
    }

    .link-container span {
        font-size: 1.3rem;
    }

    .link-container a {
        font-size: 1.4rem;
    }

    .nextProjectLink {
        font-size: 1.4rem;
    }
}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 1300px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 1300px) {

    .projectContent .slide iframe {
        width: 770px;
        height: 460px;
    }

    .base-scroll {
        margin: 0;
    }

    .circleProgress {
        transform: translateY(-2em);
    }

    .arrowBtnContainer {
        width: 100%;
        max-width: 1200px;
        padding: 0 150px;
        box-sizing: border-box;
    }
}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 1200px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 1200px) {

    #logo {
        position: fixed;
        top: 0;
        left: 1.5rem;
        z-index: 997;
        width: 100vw;
        background-color: #000113;
        padding-top: 1rem;
    }

    #logo img {
        height: 40px;
        width: 180px;
        margin-top: 0.5rem;
    }

    #open-menu-mobile {
        top: 0.6rem;
    }

    .project-option-container:hover .project-image {
        height: 120px;
    }

    .project-option-container:hover .project-image-container {
        border: none;
        padding: 0;
    }

    .project-option-container:hover .project-name {
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 500;
    }

    #projects-btn, #mision-btn-id, #open-contact-us {
        display: none;
    }

    .mouse-scroll {
        display: none;
    }

    .hand-scroll {
        display: block;
        width: 45px;
    }

    .collaborationBoxBaseText {
        font-size: 1.3rem;
    }

    .projectContent iframe {
        height: 400px;
    }

    .base-scroll {
        height: 60vh;
        margin: 4em 0;
    }

    .projectContent .slide iframe {
        width: 700px;
        height: 430px;
    }

    .circleProgress {
        transform: translateY(2em);
    }

    .arrowBtnContainer {
        padding: 0 130px;
    }


    /*//// menu mobile ////*/
    #open-menu-mobile {
        display: block;
    }

    .fixed-icon-btn {
        padding: 1.5rem 2rem 2rem 2rem;
    }

    svg.iconProject, svg.iconMision, svg.iconContact {
        height: 55px;
    }

    .fixed-icon-btn h2 {
        font-size: 1.2rem;
        margin: 10px 0 0 0;
    }

}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 1100px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 1100px) {

    .base-scroll {
        height: 60vh;
    }

    .projectContent .slide iframe {
        width: 670px;
        height: 400px;
    }

    .arrowBtnContainer {
        padding: 0 110px;
    }
}

    /*////////////////////////////////////////////////////////
    /////////////////// max-width: 1024px ////////////////////
    //////////////////////////////////////////////////////*/

@media (max-width: 1024px) {

    .logo-container .logo {
        height: 42px;
    }

    .page.header p {
        width: 65%;
        max-width: 420px;
    }

    main .page h2 {
        font-size: 1.5rem;
    }

    main .page p, .elem p span {
        font-size: 1.5rem;
        line-height: 1.5;
    }

    .elem {
        width: 75%;
        max-width: 650px;
    }

    .elem {
        width: 55%;
        max-width: 700px;
    }

    .elem h3 {
        font-size: 1.4rem;
    }

    .orbit-circle-menu {
        margin-left: -8rem;
    }

    .project-option-container {
        margin-left: 12rem;
    }

    .projectContent iframe {
        margin: 3rem auto 0;
    }

    .base-scroll {
        height: 50vh;
    }

    .projectContent .slide iframe {
        width: 550px;
        height: 350px;
    }

    .arrowBtnContainer {
        padding: 0;
        width: 70%;
    }

    /*.circleProgress {
        transform: translateY(-50px);
    }*/

    .project-option-container:nth-child(3) {
        margin-left: 14rem;
    }

    .project-option-container:nth-child(4) {
        margin-left: 14rem;
    }

    .summaryParagraph, .text-more-info {
        margin: 0;
    }

    .summaryParagraphContainer {
        border: none;
        margin-bottom: 6rem;
    }

    .contact-us-info p {
        font-size: 1.4rem;
    }

    .projectContent .faduIframe {
        margin-bottom: -20px;
    }

    /*////////////////// intro 3D ///////////////////*/
    .introContainer {
        position: inherit;
        margin-left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 7rem;
    }

    .introContainer p {
        max-width: 50%;
        margin: 0 180px;
        font-size: 1.4rem;
        font-weight: 300;
        text-align: center;
        margin-top: 2rem;
        letter-spacing: 2px;
    }

    .project-logo {
        max-height: 70px;
    }

    #canvas-container {
        display: none;
    }

    .main-project-img-vertical, .main-project-img-horizontal {
        display: block;
    }

    .main-project-img-horizontal {
        width: 50%;
    }

}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 768px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 950px){
    .arrowBtnContainer {
        padding: 0;
        width: 90%;
    }
}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 768px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 768px) {

    #logo {
        padding-bottom: .8rem;
    }

    img#logo-fixed-id {
        height: 35px;
    }

    .container-aurora-img-vertical {
        margin: 3em auto 0;
    }

    .main-project-img-vertical {
        width: 55%;
    }

    .main-project-img-horizontal {
        margin: 5rem auto 0;
        width: 60%;
    }

    .introContainer p {
        max-width: 70%;
    }

    main .page h2, .elem h3 {
        font-size: 1.3rem;
    }

    main .page p, .elem p span {
        font-size: 1.3rem;
        line-height: 1.7;
    }

    .orbit-circle-menu {
        margin-left: -14rem;
    }

    #project-menu {
        transform: scale(.9);
    }

    .project-option-container {
        margin-left: 5rem;
    }

    .project-option-container:nth-child(3), .project-option-container:nth-child(4) {
        margin-left: 7rem;
    }

    .project-name {
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 500;
    }

    .img-container {
        flex-direction: column;
        align-items: center;
    }

    .imageVerticalProject:first-child {
        margin-bottom: 3rem;
    }

    .imageVerticalProject {
        width: 75%;
    }

    .collaboratorsInfoContainer {
        width: 100%;
    }

    .projectContent iframe {
        height: 300px;
        margin: 2rem auto 0;
    }

    .base-scroll {
        height: 40vh;
    }

    .projectContent .slide iframe {
        width: 65%;
        height: 250px;
    }

    .arrowBtnContainer {
        width: 95%;
        padding: 0;
    }

    a.slides-arrow-btn svg{
        height: 22px;
    }

    .introContainer p {
        max-width: 500px;
        margin-top: 2rem;
        width: 65%;
    }

    .link-container span, .link-container a {
        font-size: 1.3rem;
    }

    .nextProjectLink {
        font-size: 1.4rem;
    }

}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 650px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 650px) {
    .projectContent .slide iframe {
        height: 190px;
    }
}

/*////////////////////////////////////////////////////////
/////////////////// max-width: 500px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 500px) {
    .projectContent .slide iframe {
        width: 320px;
    }
}


/*////////////////////////////////////////////////////////
/////////////////// max-width: 425px ////////////////////
//////////////////////////////////////////////////////*/

@media (max-width: 425px) {

    .logo-container .logo {
        height: 35px;
    }

    .project-logo {
        max-width: 120px;
    }

    .projectContent {
        max-width: inherit;
        width: 80%;
    }

    .introContainer p {
        width: 80%;
    }

    .page.header p {
        width: 75%;
        max-width: 300px;
    }

    main .page h2 {
        font-size: 1.2rem;
    }

    main .page p, .elem p span {
        font-size: 1.2rem;
        line-height: 1.8;
    }

    .elem {
        width: 75%;
        max-width: 650px;
    }

    .elem h3 {
        font-size: 1.1rem;
    }

    .orbit-circle-menu {
        margin-left: -18rem;
    }

    .project-option-container {
        margin-left: 2rem;
    }

    .project-option-container:nth-child(3), .project-option-container:nth-child(4) {
        margin-left: 4rem;
    }

    .projectContent iframe {
        height: 200px;
        margin: 4rem auto 3rem;
    }

    .circleProgress {
        transform: translateY(-2em);
    }

    .summaryParagraph, .text-more-info {
        font-size: 1.2em;
    }

    .imageVerticalProject {
        width: 90%;
    }

    .collaboratorsInfoContainer {
        padding: 15px 30px 30px;
    }

    .collaborationBoxBaseText {
        font-size: 1.1rem;
    }

    .collaborator {
        font-size: 1.1rem;
    }

    .collaborator span {
        font-weight: 600;
    }

    .links-container {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .link-container span, .link-container a {
        font-size: 1.2rem;
    }

    .contact-us-info p {
        font-size: 1.2rem;
    }

    .nextProjectLink {
        font-size: 1.2rem;
        font-weight: 100;
        color: #9e9e9e;
    }

    .projectContent .faduIframe {
        margin: 4rem auto 3rem;
    }

}



@media (min-height: 600px) {
    .circleProgress {
        transform: translateY(-2em) scale(0.8);
    }
}