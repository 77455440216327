

@keyframes hideshow {
    0% { opacity: 1; }
    10% { opacity: 1; }
    15% { opacity: 0; }
    100% { opacity: 0; }
}

/*ver para cambiar de color https://css-tricks.com/change-color-of-svg-on-hover/*/
.mision-icon:hover {
    animation: hideshow 1s ease infinite;
}



/* navigation icons*/

svg.iconProject, svg.iconMision, svg.iconContact {
    height: 45px;
    fill: none;
}

svg.iconProject .st0, svg.iconMision .st0, svg.iconContact .st0{
    fill:white
}


svg.iconProject .projectIconLayer1{
    transition: transform 3s;
    transform-origin: 50% 50%;
}

#projects-btn:hover svg.iconProject .projectIconLayer1{
    transform: rotate(720deg);
    transition-duration: 8s;
    transition-timing-function: linear;
    transform-origin: 50% 50%;
}


#projects-btn:hover svg.iconProject .projectIconLayer2{
    transform: rotate(720deg);
    transition-duration: 6s;
    transition-timing-function: linear;
    transform-origin: 50% 50%;
}

svg.iconProject .projectIconLayer2{
    transition: transform 3s;
    transform-origin: 50% 50%;
}

svg.iconMision:hover rect{
    animation: scan 2s ease infinite;
}

@keyframes scan {
    0% { transform: translateY(0); }
    33% { transform: translateY(-35%); }
    66% { transform: translateY(35%); }
    100% { transform: translateY(0); }
}

#open-contact-us:hover .iconContact #fire1{
    animation: fire1 .5s ease infinite;
}

@keyframes fire1 {
    0% { opacity: 1; }
    20% { opacity: 1; }
    40% { opacity: 0; }
    60% { opacity: 0; }
    80% { opacity: 0; }
    100% { opacity: 0; }
}

#open-contact-us:hover .iconContact #fire2{
    animation: fire2 .5s ease infinite;
}

@keyframes fire2 {
    0% { opacity: 0; }
    20% { opacity: 0; }
    40% { opacity: 1; }
    60% { opacity: 1; }
    80% { opacity: 0; }
    100% { opacity: 0; }
}

#open-contact-us:hover .iconContact #fire3{
    animation: fire3 .5s ease infinite;
}

@keyframes fire3 {
    0% { opacity: 0; }
    20% { opacity: 0; }
    40% { opacity: 0; }
    60% { opacity: 0; }
    80% { opacity: 1; }
    100% { opacity: 1; }
}

#open-contact-us:hover .iconContact{
    animation: travel 3s ease infinite;
}

@keyframes travel {
    0% { transform: translateY(0); }
    33% { transform: translateY(-3px); }
    66% { transform: translateY(3px); }
    100% { transform: translateY(0); }
}

.hand-scroll{
    animation: swipeHand 3s ease infinite;
}

@keyframes swipeHand {
    0% { transform: scale(1); }
    20% { transform: scale(.8); }
    40% { transform: translateY(-30px); }
    60% { transform: translateY(-30px); }
    80% { transform: translateY(0); }
}

@keyframes floating {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}